/* 悬浮按钮样式 */
.floating-button {
    position: fixed;
    right: 20px;
    top: 80%;
    transform: translateY(-50%);
    background: linear-gradient(to bottom right, #fcff30, #2bff72); /* 左上角到右下角的渐变 */

    color: rgb(255, 35, 35);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
    width: 270px;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    font-weight: bolder;
}

/* 悬浮窗样式 */
.floating-window {
    position: fixed;
    right: 0; /* 将悬浮窗固定在右边 */
    top: 0; /* 设置悬浮窗在页面顶部对齐 */
    width: 100%; /* 占满整个屏幕宽度（在手机上） */
    max-width: 600px; /* 最大宽度 */
    height: 100vh; /* 高度为视窗高度，确保内容占满屏幕 */
    background: linear-gradient(to bottom right, #8b8b8b, #ffffff);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 0; /* 去除圆角，保持简洁 */
    z-index: 1000;
    overflow-y: auto; /* 垂直方向允许滚动 */
    overflow-x: hidden; /* 禁止水平滚动 */
}

/* 标语和关闭按钮部分样式 */
.floating-window-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom right, #4fb0ff, #3ef2ff);
    padding: 10px;
    position: sticky; /* 使标题部分在滚动时固定 */
    top: 0; /* 置顶固定 */
    z-index: 10; /* 保证标题在其他内容之上 */
}

/* 标语的样式 */
.banner {
    font-size: 24px;
    color: white;
    margin: 0;
}

/* 关闭按钮样式 */
.close-button {
    background-color: #ff5c5c; /* 关闭按钮背景颜色 */
    border: none;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

/* 文字部分样式 */
.floating-window p {
    background-color: #ffffff; /* 文本部分背景颜色 */
    padding: 15px;
    border-radius: 5px;
    color: #525252;
    margin-top: 10px;
}
